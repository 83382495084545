<template>
    <content-view>
        <router-link class="btn materials-diff__back-btn mb-10" title="Назад"
                     :to="`/knowledge/article/${this.$route.params.id}`">
            <i class="fa fa-angle-left mr-5"></i> Назад
        </router-link>
        <div class="row row-deck" v-if="current_version && diff">
            <div class="col-xl-6">
                <div class="block">
                    <div class="materials-diff__top-header block">
                        <div class="block-content">
                            <label>Текущая версия</label>
                            <div class="one-material__date mt-5 mb-5">
                                {{ current_version.data_change }}
                            </div>
                        </div>
                    </div>

                    <div class="block-header block-header-default full-page-info-box">
                        <div class="title-box">
                            <h2 v-if="diff.from.number" class="materials-diff__title">
                                <span v-html="diff.from.number"></span>. <span v-html="diff.from.title"></span>
                            </h2>
                            <h2 v-else class="materials-diff__title" v-html="diff.from.title"></h2>
                        </div>
                        <div class="one-material__learn-time" v-if="diff.from.timepiece">
                            Время на изучение:&nbsp;<span v-html="diff.from.timepiece"></span>
                        </div>
                    </div>

                    <div
                        class="block-content one-material__article"
                    >
                        <div v-html="diff.from.text"></div>
                        <div v-if="diff.from.user_fio">
                            <hr/>
                            Редактор: <span v-html="diff.from.user_fio"></span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xl-6">
                <div class="block box-shadow-none">
                    <div class="materials-diff__top-header block">
                        <div class="block-content">
                            <label>Сравниваемая версия</label>
                            <div class="form-material">
                                <v-select2
                                    @change="loadData"
                                    class="pagination__select"
                                    v-model="version"
                                    :reduce="(label) => label.id"
                                    label="label"
                                    :clearable="false"
                                    :searchable="false"
                                    :options="versionList"
                                >
                                </v-select2>
                            </div>
                        </div>
                    </div>

                    <div class="block-header block-header-default full-page-info-box">
                        <div class="title-box">
                            <h2 v-if="diff.to.number" class="materials-diff__title">
                                <span v-html="diff.to.number"></span>. <span v-html="diff.to.title"></span>
                            </h2>
                            <h2 v-else class="materials-diff__title" v-html="diff.to.title"></h2>
                        </div>
                        <div class="one-material__learn-time" v-if="diff.to.timepiece">
                            Время на изучение:&nbsp;<span v-html="diff.to.timepiece"></span>
                        </div>
                    </div>

                    <div
                        class="block-content one-material__article"
                    >
                        <div v-html="diff.to.text"></div>
                        <div class="one-material__author" v-if="diff.to.user_fio">
                            <hr/>
                            Редактор: <span v-html="diff.to.user_fio"></span>
                            <hr>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row row-deck">
            <div class="col-xl-6">
                <div class="block box-shadow-none">
                    <div class="materials-diff__mark-wrap">
                        <div class="materials-diff__mark">Добавлено</div>
                        <div class="materials-diff__mark materials-diff__mark_dell">
                            Удалено
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </content-view>
</template>

<script>
import ContentView from '@/views/menu/ContentView'
import {metaTitle} from '@/mixins/meta_title'
import session from "@/api/session";

export default {
    name: 'MaterialDiff',
    mixins: [metaTitle],
    components: {ContentView},
    data() {
        return {
            current_version: {},
            version: '',
            diff: null,
            versionList: []
        };
    },
    async created() {
        await this.loadData()
    },
    watch: {
        version: {
            handler: function (val, oldVal) {
                if (oldVal && val !== oldVal) {
                    this.loadData();
                }
            },
            deep: true
        },
    },
    methods: {
        async loadData() {
            const params = {
                change: this.version
            };
            try {
                const request = session.get(`/api/v1/material/${this.$route.params.id}/diff/`, {params});
                let response = await request;
                this.diff = response.data['diff'];
                this.current_version = response.data['current_version'];
                this.versionList = response.data['data_change'];
                this.version = response.data['change_version'].data_change;
            } catch (err) {
                console.log('err', err)
            }
        },
    }
}
</script>

<style>
@import "#sass/v-style";
.diff_add {
    background-color: #d1ebbb;
    padding: 0 5px;
}

.diff_add * {
    background-color: #d1ebbb;
}

.diff_sub {
    padding: 0 5px;
    background-color: #faa3ad;
}

.diff_sub * {
    background-color: #faa3ad;
}

.one-material__article {
    margin-top: 0;
}

.materials-diff__back-btn {
    color: #000000;
    font-size: 15px;
    line-height: 1;
    background-color: #ffffff;
    border-radius: 20px;
}

.materials-diff__top-header {
    min-height: 95px;
    background-color: #f8f8f8;
}

.materials-diff__top-header.block {
    margin-bottom: 0;
}

.block-header-default {
    padding: 10px 20px 0;
    background-color: #fff;
}

.block-content img {
    margin: 1rem;
}

.block-content img.diff_add,
.block-content img.diff_sub {
    margin: 0.75rem;
    padding: 0.25rem;
    border-radius: 6px;
}

.materials-diff__title {
    margin: 0;
    max-width: 80%;
    font-size: 19px;
    font-weight: 600;
    word-wrap: break-word;
    word-break: break-word;
    color: #000;
}

.one-material__learn-time {
    margin-top: 14px;
    color: #000000;
    padding-left: 28px;
    background-repeat: no-repeat;
    background-position: left center;
    background-image: url("../../../assets/img/favicons/watch.png");
    font-weight: 700;
}

.one-material__learn-time span {
    margin-left: 5px;
}

.one-material__learn-time svg {
    margin-right: 5px;
}

.one-material__article {
    overflow: auto;
}

.one-material__author {
    padding-bottom: 10px;
}

.materials-diff__mark-wrap {
    display: flex;
}

.materials-diff__mark {
    color: #000;
    padding: 10px 20px;
}

.materials-diff__mark::before {
    content: "";
    display: inline-block;
    margin-right: 10px;
    width: 10px;
    height: 10px;
    background-color: #d1ebbb;
}

.materials-diff__mark_dell::before {
    background-color: #faa3ad;
}
</style>
